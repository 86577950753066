<template>
  <div class="pt-2 pb-5">
    <div v-if="selectable">
      Sélectionnées <PTag>{{ saved.length }}</PTag>
      <button @click.prevent="clearSelection" class="btn btn-sm btn-danger">
        <i class="bi bi-close"></i>Vider
      </button>
    </div>
    <div class="bg">
      <!-- <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <slot name="listTitle">
            <h6>{{ listTitle }}</h6>
          </slot>
        </div>
      </div> -->
      <div>
        <slot name="listDescription"></slot>
      </div>
      <div class="bg-white p-3 border shadow-0">
        <SignatureEditorDialog @save="onSignatureSigned" ref="signatureEditor" />
        <div class="container- mt-1">
          <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
          <div class="bg-white p-2 rounded-4">
            <form class="bg-" method="post" accept-charset="utf-8" action="">
              <div class="row py-0 p-fluid grid formgrid">
                <div class="col field col">
                  <form class="d-flex justi">
                    <div class="input-group">
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        placeholder="Rechercher par nom et prénom"
                      />
                    </div>
                  </form>
                </div>
                <div class="col-md-auto">
                  <div class="d-flex justify-">
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="typeDe"
                      :options="typeDeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Type DE'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="sexeDe"
                      :options="sexeDeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Sexe DE'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="participationDeStatus"
                      :options="participationDeStatusOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Pariticipé option'"
                      display="chip"
                    />
                    <a
                      href="#"
                      @click.prevent="mxSubmitQuery"
                      class="style_btn btn btn-primary me-1"
                      id="button-query"
                      ><i class="bi bi-search"></i
                    ></a>
                    <PButton
                      class="p-button-danger p-button-outlined py-1 p-button-sm me-1"
                      icon="pi pi-times"
                      @click.prevent.stop="
                        filterOptions.forEach((option) => (option.value = []))
                      "
                    >
                    </PButton>
                    <button
                      type="button"
                      @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                      class="style_btn btn btn-warning"
                      id="button-query"
                    >
                      <i class="bi bi-filter"></i>
                    </button>
                    <!-- <button type="button" @click.prevent.stop="filterOptions.forEach((option) => option.value = [])" class="style_btn btn btn-warning"  id="button-query"><i class="bi bi-filter" ></i></button> -->
                    <!-- <div class="d-flex justify-content-end mt-2">
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="row py-0 mt-4 grid formgrid">
                  <!-- <div class="field col-12 col-md-6">
                      <p-calendar
                        placeholder="Date de début"
                        v-model="dateDeb"
                        locale="fr"
                        :showIcon="true"
                        :manualInput="false"
                      />
                                  
                    </div>
                    <div class="field col-12 col-md-6">
                      <p-calendar
                        placeholder="Date de fin"
                        v-model="dateFin"
                        :showIcon="true"
                        :manualInput="false"
                        />
                    </div> -->
                </div>
                <!-- <div class="">
                    <button type="button"  @click.prevent="showAdvancedFilter = !showAdvancedFilter" class="style_btn btn btn-warning"  id="button-query"><i class="bi bi-filter" ></i></button>
                  </div> -->
              </div>
            </form>
            <transition>
              <div
                class="card mx- border-radius-5 mt-2 hadow-5"
                v-if="showAdvancedFilter"
              >
                <div class="row p-fluid p-3">
                  <div class="row grid">
                    <template class="mb-2">
                      <div class="field col-12 col-sm-6 col-md-4">
                        <p-calendar
                          placeholder="Date de début"
                          v-model="dateDeb"
                          locale="fr"
                          :showIcon="true"
                          :manualInput="false"
                        />
                      </div>
                      <div class="field col-12 col-sm-6 col-md-4">
                        <p-calendar
                          placeholder="Date de fin"
                          v-model="dateFin"
                          :showIcon="true"
                          :manualInput="false"
                        />
                      </div>
                    </template>
                    <template v-for="(option, index) in filterOptions">
                      <div
                        class="col-12 col-sm-6 col-md-4 mb-4"
                        v-if="option.visible"
                        :key="index"
                      >
                        <AsyncMultiSelect
                          v-if="!option.isEchelon"
                          v-model="option.value"
                          :multiple="true"
                          :queryUrl="option.queryUrl"
                          :optionLabel="option.labelField"
                          :placeholder="option.label"
                          :emitObject="false"
                          :displayMode="option.displayMode"
                          :searchOptions="[option.labelField]"
                        />
                        <tempalte v-else>
                          <c-auto-complete
                            v-if="option.url || option.urlBuilder"
                            display="chip"
                            v-model="option.value"
                            chips
                            :option="option"
                            :force-selection="true"
                          />
                        </tempalte>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="table-responsive">
          <b-table
            show-empty
            empty-text="Aucune donnée à afficher"
            :fields="tableFields"
            class="align-middle"
            striped
            select-mode="multi"
            ref="table"
            selectable
            :sort-by.sync="mxPagination.sortBy"
            :sort-desc.sync="mxPagination.sortDesc"
            responsive="sm"
            @row-selected="onRowSelected"
            :items="tableData"
          >
            <template #head()="data">
              <span>{{ data.label }} </span>
            </template>
            <template #head(index)="">
              <span> N° </span>
            </template>

            <template #head(#)="data">
              <div class="form-check">
                <input
                  @change="selectAll"
                  class="form-check-input"
                  type="checkbox"
                  :id="`${data.index}`"
                  name="disponible"
                  :value="false"
                />
              </div>
            </template>
            <template #cell(#)="data">
              <div class="form-check">
                <input
                  @input="selectionChanged($event, data)"
                  :checked="data.rowSelected"
                  class="form-check-input"
                  type="checkbox"
                  :id="`${data.index}`"
                  name="disponible"
                />
                <!-- {{ data.index + 1 }} -->
              </div>
            </template>

            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(enabled)="data">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  v-model="data.item.enabled"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  :checked="data.item.enabled"
                />
                <label class="form-check-label" for="flexSwitchCheckChecked">{{
                  data.item.enabled ? "Actif" : "Inactif"
                }}</label>
              </div>
            </template>
            <template #cell(estSuivi)="{ item }">
              <div class="position-relative">
                <PTag
                  rounded
                  :severity="item.estSuivi ? 'primary' : 'danger'"
                  size="large"
                  >{{ item.estSuivi ? "Affecté" : "Non.Affecté" }}</PTag
                >
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="d-flex justify-content-end">
                <slot name="actions" :data="data">
                  <button class="btn btn-sm py-1 btn-outline-primary">
                    <i class="bi bi-journal-check"></i>
                  </button>
                </slot>
              </div>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              <span>{{ data.value }}</span>
            </template>
            <template #cell(participant.nom)="data">
              <span
                v-if="!data.item.participant.ficheValide"
                class="d-flex justify-content"
              >
                {{ data.item.participant.nom }}
              </span>
              <PMessage
                v-else
                class="my-0 p-0 msg-cloture"
                severity="success"
                @close="data.item.participant.ficheValide = true"
              >
                {{ data.item.participant.nom }}
                - validé avec succès.
              </PMessage>
            </template>
            <template #cell(presence)="data">
              <span class="d-flex justify-content-">
                <PInputSwitch
                  @click.stop=""
                  v-model="data.item.presence"
                  @change="presenceChange(data.item)"
                  class="me-3"
                />
                <span
                  v-tooltip.top="'Présence non spécifiée'"
                  v-if="data.item.presence == null"
                  >N/A</span
                >
                <PTag
                  v-tooltip.top="getPresenceLabel(data.item)"
                  :severity="getPresenceColor(data.item)"
                  v-else
                  >{{ getPresenceLabel(data.item) }}</PTag
                >
              </span>
            </template>
            <template #cell(createdDate)="{ item: de }">
              <div class="d-flex justify- mb-3">
                {{ $dayjs(de.createdDate).format("DD/MM/YYYY") }}
              </div>
            </template>
            <template #cell(presenceStatus)="data">
              <span
                v-tooltip.top="'Présence non spécifiée'"
                v-if="data.item.presence == null"
                >N/A</span
              >
              <span class="d-flex justify-content-" v-else
                ><PTag
                  v-tooltip.top="getPresenceLabel(data.item)"
                  :severity="getPresenceColor(data.item)"
                  >{{ getPresenceLabel(data.item) }}</PTag
                ></span
              >
            </template>
            <template #cell(classement)="data">
              <span
                v-tooltip.top="'Pas encore évalué'"
                v-if="data.item.classement == null"
                >N/A</span
              >
              <PBadge
                v-else
                v-tooltip.top="data.item.classement.description"
                :value="data.item.classement.code"
                :severity="getClassementColor(data.item.classement.code)"
              />
            </template>
            <template #cell(motifAbsence)="data">
              <div class="d-flex justify-content-between">
                <span
                  v-tooltip.top="
                    data.item.motifAbsence != null
                      ? data.item.motifAbsence
                      : 'Aucun contenu. Cliquez pour éditer le contenu.'
                  "
                  @click.prevent.stop="
                    showModitifEditorPopup(
                      $event,
                      '_motif_editor_' + data.item.id,
                      data.item
                    )
                  "
                  class="d-flex justify-content- text-ellipsis w-100"
                  style=""
                >
                  {{ data.item.motifAbsence }}
                </span>
                <ConfirmPopup
                  style="z-index: 10000"
                  :group="'_motif_editor_' + data.item.id"
                >
                  <template #message>
                    <div class="card shadow-0 border-0 p-3 mb-2">
                      <h6>
                        {{
                          data.item.presence != true
                            ? "Motif d'absence du DE "
                            : "Observation sur le DE "
                        }}
                        {{ data.item.participant.nom }}
                      </h6>
                      <textarea
                        class="form-control"
                        v-model="data.item.motifAbsence"
                        cols="40"
                        rows="4"
                      ></textarea>
                    </div>
                  </template>
                </ConfirmPopup>
                <PButton
                  class="p-button-text p-button-sm"
                  v-tooltip.left="'Editer le contenu'"
                  icon="bi bi-pen"
                  @click.prevent.stop="
                    showModitifEditorPopup(
                      $event,
                      '_motif_editor_' + data.item.id,
                      data.item
                    )
                  "
                ></PButton>
                <!-- <PButton class="p-button-text p-button-sm" v-tooltip.left="'Sauvegarder le contenu'" icon="pi pi-check" @click.prevent.stop="activeMotifEditorId = null"></PButton> -->
              </div>
            </template>
            <template #cell(urlSignature)="data">
              <div
                class="d-flex justify-content-center border bg-light"
                v-if="data.item.presence == true"
              >
                <span v-if="data.item.fullSignatureUrl != null"
                  ><img :src="data.item.fullSignatureUrl" height="30px" width="130px"
                /></span>
                <PButton
                  v-else
                  class="p-button-text p-button-sm"
                  v-tooltip.left="
                    'Signer ma présence en tant que DE ' + data.item.participant.nom
                  "
                  icon="bi bi-voicemail"
                  @click.prevent.stop="showSignatureEditor($event, data.item)"
                ></PButton>
              </div>
            </template>
            <template #row-details="row">
              <slot name="rowDetails" :row="row"> rowDetails slot not provided </slot>
            </template>
          </b-table>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <p>
            Affichage de l’élément
            {{ mxPagination.page * mxPagination.rowsPerPage }} à
            {{ mxPagination.page * mxPagination.rowsPerPage }} de
            {{ mxTotalCount }} éléments
          </p>
          <b-pagination
            v-model="mxPagination.page"
            :total-rows="mxTotalCount"
            :per-page="mxPagination.rowsPerPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { multiPageItemSelectorMixin } from "@/mixins/multipage-item-selector-mixin";
import CAutoComplete from "./CAutoComplete.vue";
import AsyncMultiSelect from "../../components/common/AsyncMultiSelect.vue";
import ConfirmPopup from "primevue/confirmpopup";
import SignatureEditorDialog from "../espace/common/signature/SignatureEditorDialog.vue";

export default {
  props: {
    listTitle: {
      type: String,
      default: "Référentiel non précisé",
    },
    referentielName: {
      type: String,
      required: true,
    },
    tableFields: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    emptyReferentielItem: {
      type: Object,
      required: true,
    },
    dataFormatter: {
      type: Function,
    },
    extraQueryArgsParamBuilder: {
      type: Function,
      default: null,
    },
    paginatorConfig: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  components: {
    CAutoComplete,
    AsyncMultiSelect,
    ConfirmPopup,
    SignatureEditorDialog,
  },
  mixins: [paginatorMixin, multiPageItemSelectorMixin],
  data() {
    return {
      selected: [],
      selectedItems: [],
      saved: [],
      search: "",
      activeItem: null,
      dateDeb: null,
      dateFin: null,
      activeMotifEditorId: null,
      showAdvancedFilter: false,
      activeReferentiel: { ...this.emptyReferentielItem },
      searchFields: ["nomPrenom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nomPrenom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
      ],
      typeDeOptions: [
        { value: "", label: "Tous" },
        { value: "PPI", label: "PPI" },
        { value: "CI", label: "CI" },
        { value: "INSTRUIT", label: "INSTRUIT" },
      ],
      participationDeStatusOptions: [
        { value: "", label: "Tous" },
        { value: "PARTICIPE", label: "PARTICIPE" },
        { value: "PAS_PARTICIPE", label: "PAS PARTICIPE" },
      ],
      participationDeStatus: null,
      sexeDeOptions: [
        { value: "", label: "Tous" },
        { value: "M", label: "Masculin" },
        { value: "F", label: "Féminin" },
      ],
      sexeDe: null,
      clientTypeOptions: [
        { value: "", label: "Tous" },
        { value: "CLIENT", label: "Client" },
        { value: "NON_CLIENT", label: "Non Client" },
      ],
      demandeurVulnerabiliteOptions: [
        { value: null, label: "Tous" },
        { value: "VULNERABLE", label: "VULNERABLE" },
        { value: "NON_VULNERABLE", label: "NON VULNERABLE" },
      ],
      demandeurVulnerabilite: null,
      clientType: null,
      typeDe: "AFFECTABLE",
      filterOptions: [
        {
          column: "departement",
          isEchelon: true,
          key: "nom",
          valueKey: "id",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: true,
        },
        {
          column: "commune",
          key: "nom",
          isEchelon: true,
          valueKey: "id",
          value: "",
          label: "Commune",
          dropdown: true,
          enabled: () => {
            return this.filterOptions[0].value && this.filterOptions[0].value.id;
          },
          urlBuilder: () => {
            return `/departements/${this.filterOptions[0].value.id}/communes`;
          },
          visible: true,
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "id",
          isEchelon: true,
          value: "",
          dropdown: true,
          label: "Arrondissement",
          enabled: () => {
            return this.filterOptions[1].value && this.filterOptions[1].value.id;
          },
          urlBuilder: () => {
            return `/communes/${this.filterOptions[1].value.id}/arrondissements`;
          },
          visible: true,
        },
        {
          column: "ville",
          isEchelon: true,
          key: "nom",
          valueKey: "id",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier",
          enabled: () => {
            return this.filterOptions[2].value && this.filterOptions[2].value.id;
          },
          urlBuilder: () => {
            return `/arrondissements/${this.filterOptions[2].value.id}/villes`;
          },
          visible: true,
        },
        {
          column: "langue",
          value: "",
          label: "Langues",
          dropdown: true,
          labelField: "nom",
          displayMode: "mega-menu",
          valueKey: "id",
          queryUrl: "ethnies",
          visible: true,
        },
        {
          column: "metierAppris",
          value: "",
          label: "Métier(s) appris",
          valueKey: "id",
          queryUrl: "romes",
          displayMode: "classic",
          labelField: "libelle",
          dropdown: true,
          visible: true,
        },
        {
          column: "metierDesires",
          value: "",
          label: "Métier(s) recherché(s)",
          queryUrl: "romes",
          valueKey: "id",
          labelField: "libelle",
          displayMode: "classic",
          dropdown: true,
          visible: true,
        },
        {
          column: "competence",
          value: "",
          label: "Aptitudes",
          getterName: "competences",
          valueKey: "id",
          displayMode: "mega-menu",
          labelField: "intitule",
          dropdown: true,
          visible: true,
        },
        {
          column: "diplome",
          value: "",
          label: "Diplômes",
          valueKey: "id",
          dropdown: true,
          displayMode: "mega-menu",
          labelField: "code",
          queryUrl: "niveaux",
          visible: true,
        },
        {
          column: "niveauInstruction",
          value: "",
          label: "Niveau instruction",
          dropdown: true,
          valueKey: "id",
          labelField: "code",
          displayMode: "mega-menu",
          queryUrl: "instructions",
          visible: true,
        },
      ],
      filterOptionsOld: [
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: true,
        },
        {
          column: "commune",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          enabled: () => {
            return this.filterOptions[0].value && this.filterOptions[0].value.id;
          },
          urlBuilder: () => {
            return `/departements/${this.filterOptions[0].value.id}/communes`;
          },
          visible: true,
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          label: "Arrondissement",
          enabled: () => {
            return this.filterOptions[1].value && this.filterOptions[1].value.id;
          },
          urlBuilder: () => {
            return `/communes/${this.filterOptions[1].value.id}/arrondissements`;
          },
          visible: true,
        },
        {
          column: "ville",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier",
          enabled: () => {
            return this.filterOptions[2].value && this.filterOptions[2].value.id;
          },
          urlBuilder: () => {
            return `/arrondissements/${this.filterOptions[2].value.id}/villes`;
          },
          visible: true,
        },
      ],
    };
  },
  watch: {
    typeDe() {
      this.makeQuery();
    },
  },
  created() {
    this.makeQuery();
  },
  computed: {
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    referentielCaptilizedName() {
      let lowerName = this.referentielName;
      return lowerName[0].toUpperCase() + lowerName.slice(1, lowerName.length);
    },
    referentielLowercaseName() {
      return this.referentielName; //Not use toLowerCase
    },
  },
  methods: {
    makeQuery() {
      this.mxInitializePaginator({
        queryModel: this.referentielName,
        search: this.search,
        fetcherMethod: `${this.referentielName}/fetch${this.referentielCaptilizedName}s`,
        dataGetter: `${this.referentielName}/${this.referentielName}s`,
        paginationGetter: `${this.referentielName}/pagination`,
        autoSubmitQuery: false,
        pagination: {
          sortBy: "nom",
          sortDesc: false,
          descending: false,
          page: 0,
          rowsPerPage: 10,
        },
        filterOptions: this.filterOptions,
        searchFields: this.searchFields,
        extraQueryArgsParamBuilder:
          this.extraQueryArgsParamBuilder == null
            ? () => {
                const dateDeb = (this.dateDeb || new Date("2021-12-31"))
                  .toISOString()
                  .substring(0, 10);
                const dateFin = (this.dateFin || new Date("2080-12-31"))
                  .toISOString()
                  .substring(0, 10);

                let paramPlus = this.mxAddQueryParam("regroupements", this.typeDe);
                paramPlus = this.mxAddQueryParam(
                  "vulnerabilite",
                  this.demandeurVulnerabilite,
                  paramPlus
                );
                paramPlus = this.mxAddQueryParam("sexe", this.sexeDe, paramPlus);

                let params = `dateDeb=${dateDeb}&dateFin=${dateFin}&${paramPlus}&${
                  this.extraQueryArg ? this.extraQueryArg : ""
                }`;
                return params;
              }
            : this.extraQueryArgsParamBuilder,
      });
    },
    showSignatureEditor(event, item) {
      this.activeItem = item;
      this.$refs.signatureEditor.show();
    },
    showModitifEditorPopup(event, popupGroupName, item) {
      this.$confirm.require({
        group: popupGroupName,
        target: event.currentTarget,
        // message: 'L\'activité a-t-elle pu tenir?',
        icon: "p",
        acceptLabel: "Enregistrer",
        rejectLabel: "Fermer",
        accept: () => {
          this.presenceChange(item);
        },
        reject: () => {},
      });
    },
    onSignatureSigned(signatureUrl) {
      this.activeItem.urlSignature = signatureUrl;
      this.presenceChange(this.activeItem);
      this.activeItem = null;
    },
    getPresenceLabel(item) {
      return item.presence == null ? "N/A" : item.presence ? "Présent" : "Absent";
    },
    getPresenceColor(item) {
      return item.presence == null ? "light" : item.presence ? "primary" : "danger";
    },
    getClassementColor(code) {
      let color = "primary";
      if (code == "DENA") {
        color = "danger";
      } else if (code == "DEA") {
        color = "success";
      }
      return color;
    },
    presenceChange(item) {
      this.$emit("presenceChange", item);
    },
    clearSelection() {
      this.saved = [];
      this.selected = [];
      this.$refs.table.clearSelected();
    },
  },
};
</script>

<style></style>
