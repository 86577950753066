/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="statistic-box">
      <activite-statistic ref="activiteStatistic" class="mt-3 mb-2" />
    </div>
    <CDetailViewer
      v-if="activeActivite"
      title="Vue détaillé des activités"
      ref="detailViewer"
      :item="activeActivite"
    >
      <PTabView :activeIndex.sync="activeTab">
        <PTabPanel header="Détail activité">
          <ActiviteDetail :activiteId="activeActivite.id" :item="activeActivite" />
        </PTabPanel>
        <PTabPanel header="Liste des participants">
          <ActiviteParticipantList :activite="activeActivite" />
        </PTabPanel>
        <PTabPanel header="Rapport activite"> </PTabPanel>
      </PTabView>
    </CDetailViewer>
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col-md-2">
              <p-calendar
                placeholder="Date prévue"
                class="flex-grow-1"
                v-model="datePrevue"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="col field">
              <form class="d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                  </span> -->
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      class="col-auto"
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      :multiple="false"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-3 col-sm-12 d-flex">
              <div class="input-group me-4">
                <div class="p-inputgroup">
                  <p-dropdown
                    class="flex-grow- me-2"
                    v-model="statusActivite"
                    :options="statusActiviteOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Statut'"
                    display="chip"
                  />

                  <!-- <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="demandeurVulnerabilite"
                    :options="demandeurVulnerabiliteOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Vulnérabilité'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="demandeurType"
                    :options="demandeurTypeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Type de DE'"
                    display="chip"
                  /> -->
                  <!-- <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="statutPec"
                    :options="statutPecOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Statut PEC'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="statutPlacement"
                    :options="statutPlacementOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Statut PLACE'"
                    display="chip"
                  /> -->
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <div class="field col-12 col-md-3">
                <label for="basic">Date de début</label>
                <p-calendar
                  placeholder="Date de début"
                  v-model="dateDeb"
                  locale="fr"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <div class="field col-12 col-md-3">
                <label for="basic">Date de fin</label>
                <p-calendar
                  placeholder="Date de fin"
                  class="flex-grow-1"
                  v-model="dateFin"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des tâches planifiées</h4>
        </div>
        <div v-if="$can('create', 'Azoli')" class="btn-group">
          <a
            @click.prevent="$router.push({ name: 'espace.ce.de.add' })"
            href=""
            class="style_btn btn btn-warning"
            >Programmer une activité</a
          >
        </div>
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>
      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="row mb-4" v-if="viewMode == 'card'">
            <div
              class="col-sm-4 col-lg-3 mb-3"
              v-for="act in mxFilteredItems"
              :key="act.id"
            >
              <div class="card p-3 pt-3">
                <div @click.stop="showDemandeur(act)" class="d-flex justify-content mb-">
                  <span
                    class="text-uppercase badge border-radius-5 py-1 px-2"
                    :class="getStatusColor(act)"
                    >{{ act.statusActivite }}</span
                  >
                </div>
                <div class="text mb-2">
                  <div class="d-flex no-wrap justify-content- align-items text-muted">
                    <small class="mt-1">
                      <i class="pi pi-bell"></i>
                      {{ $dayjs(act.datePrevue).format("DD-MM-YYYY HH:mm") }}</small
                    >
                  </div>
                  <h6 class="my-1 mt-3">
                    <a href="#" class="text-dark text-uppercase">{{ act.titre }}</a>
                  </h6>
                  <div class="my-1 mb-2">
                    <a href="#" class="text-">{{ act.typeActivite.code }}</a>
                  </div>
                  <small v-if="act.creePar" class="text-muted"
                    >Auteur: {{ act.creePar.nom }} {{ act.creePar.prenom }}
                    <span class="text-uppercase"
                      >, le {{ act.lastLogin | formatDate }}</span
                    ></small
                  >
                  <small v-if="act.creePar" class="text-muted"
                    >Participants: {{ act.participants }}
                  </small>
                </div>
                <div class="d-flex justify-content-center mt-2">
                  <!-- <a class="btn btn-success rounded-0 btn-sm me-1" ><i class="bi bi-pencil-square"></i></a> -->
                  <!-- <a class="btn btn-info rounded-0 btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Consulter et modifier"  @click.stop="showDemandeur(de)" ><i class="bi bi-info-square"></i></a>
                      <a class="btn btn-danger rounded-0 btn-sm me-1" title="Générer PDF" @click.stop.prevent="downloadFiche(de)"><i class="bi bi-printer"></i></a>
                      <a @click.stop.prevent="remplirFiche(de)" title="Saisir la fiche" class="btn btn-warning rounded-0 btn-sm me-1"><i class="bi bi-file-earmark-check"></i></a>
                      <button title="Valider la fiche me-1" @click.prevent="validerFiche(de)" class="btn btn-light btn-sm">  <i :class="{ 'text-danger' : de.statusActivite == 'VALIDE'}" class="bi bi-caret-left-fill"></i></button>
                      <button title="Prise en charge me-1" @click.prevent="priseEnCharge(de)" class="btn btn-primary btn-sm">  <i class="bi bi-file-earmark-medical"></i></button> -->
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div
                class="d-flex flex-wrap justify-content-center align-items-center mt-3"
              >
                <b-pagination
                  v-model="mxPagination.page"
                  :total-rows="mxTotalCount"
                  :per-page="mxPagination.rowsPerPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div v-else class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(titre)="{ item: act }">
                  <span class="text-uppercase">{{ act.titre }}</span>
                </template>
                <template #cell(datePrevue)="{ item: act }">
                  <span class="text-uppercase">{{
                    $dayjs(act.datePrevue).format("DD-MM-YYYY HH:mm")
                  }}</span>
                </template>
                <template #cell(creePar)="{ item: act }">
                  <span class="text-uppercase" v-if="act.creePar"
                    >{{ act.creePar.nom }} {{ act.creePar.prenom }}</span
                  >
                </template>
                <template #cell(statusActivite)="{ item: act }">
                  <span
                    class="text-uppercase badge border-radius-5 py-1 px-2"
                    :class="getStatusColor(act)"
                    >{{ act.statusActivite }}</span
                  >
                </template>

                <template #row-details="row">
                  <div class="card">
                    <div class="card-body">
                      <PTabView :activeIndex.sync="activeTab">
                        <PTabPanel header="Détail activité">
                          <ActiviteDetail :item="row.item" />
                        </PTabPanel>
                        <PTabPanel header="Rapport activité">
                          <ActiviteParticipantList
                            :activite="row.item"
                            ref="participantListDialog"
                            btnText="Programmer une activité"
                            title="Liste des participants"
                          />
                        </PTabPanel>
                        <PTabPanel header="Document joint">
                          <!-- <ActiviteParticipantList
                            :activite="row.item"
                            ref="participantListDialog"
                            btnText="Programmer une activité"
                            title="Liste des participants"
                          /> -->
                        </PTabPanel>
                      </PTabView>
                      <!-- <PTabView :activeIndex.sync="activeTab">
                        <PTabPanel header="Détail activité">
                          <ActiviteDetail :activiteId="row.item.id" :item="row.item" />
                        </PTabPanel>
                        <PTabPanel header="Liste des participants">
                          <ActiviteParticipantList :activite="row.item" />
                        </PTabPanel>
                        <PTabPanel header="Document joint"> </PTabPanel>
                      </PTabView> -->
                    </div>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      v-tooltip.top="'Voir les détails'"
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                    <a
                      class="btn btn-danger rounded btn-sm me-1"
                      title="Générer PDF"
                      @click.stop.prevent="downloadFiche(row.item)"
                      ><i class="bi bi-printer"></i
                    ></a>
                    <!-- <a
                      class="btn btn-info rounded-0 btn-sm me-1"
                      title="Consulter et modifier"
                      @click="showDetails(row.item)"
                      ><i class="bi bi-info-square"></i
                    ></a> -->
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mb-4" v-if="viewMode == 'card'">
          <div
            class="col-sm-4 col-lg-3 mb-3"
            v-for="i in mxPagination.rowsPerPage"
            :key="i"
          >
            <div class="style_profil_card p-3 pt-3">
              <div class="custom-skeleton p-3">
                <div
                  class="d-flex flex-column mb-2 align-items-center justify-content-center"
                >
                  <PSkeleton width="10rem" class="mb-2"></PSkeleton>
                  <PSkeleton shape="circle" size="6rem" class="mr-2 mt-2"></PSkeleton>
                  <div class="mt-4">
                    <PSkeleton width="12rem" class="mb-2"></PSkeleton>
                    <PSkeleton height=".5rem"></PSkeleton>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.ce.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import ActiviteStatistic from "../../../components/statistic/activite/ActiviteStatistic.vue";
import ActiviteParticipantList from "./activite/ActiviteParticipantList.vue";
import CDetailViewer from "../../../components/common/CDetailViewer.vue";
import ActiviteDetail from "./activite/ActiviteDetail";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
  },
  components: {
    ActiviteParticipantList,
    ActiviteStatistic,
    CAutoComplete,
    CDetailViewer,
    ActiviteDetail,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      datePrevue: null,
      dateDeb: null,
      activeActivite: null,
      activeTab: 0,
      dateFin: null,
      showAdvancedFilter: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      demandeurTypeOptions: [
        { value: null, label: "Tous" },
        { value: "PPI", label: "PPI" },
        { value: "CI", label: "CI" },
        { value: "INSTRUIT", label: "INSTRUIT" },
      ],
      demandeurType: null,
      statutPecOptions: [
        { value: null, label: "Tous" },
        { value: "PEC", label: "PEC" },
        { value: "NPEC", label: "NPEC" },
      ],
      statutPec: null,
      statutPlacementOptions: [
        { value: null, label: "Tous" },
        { value: "PLACE", label: "PLACE" },
        { value: "NON_PLACE", label: "NON PLACE" },
      ],
      statutPlacement: null,
      statusActiviteOptions: [
        { value: null, label: "Tous" },
        { value: "CREE", label: "CREE" },
        { value: "TENUE", label: "TENUE" },
        { value: "NON_TENUE", label: "NON TENUE" },
        { value: "CLOTUREE", label: "CLOTUREE" },
      ],
      statutActivite: null,
      demandeurVulnerabiliteOptions: [
        { value: null, label: "Tous" },
        { value: "VULNERABLE", label: "VULNERABLE" },
        { value: "NON_VULNERABLE", label: "NON VULNERABLE" },
      ],
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des tâches planifiées",
                subtitle: "Liste des tâches planifiées",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "activitePlanifies/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des tâches planifiées Azoli.xlsx",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                fetcherMethod: "activitePlanifies/exportToExcel",
                queryParams: params,
                fileName: "Liste des tâches planifiées Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["titre"],
      searchFieldsOptions: [
        {
          label: "Titre",
          value: "titre",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Participant",
          value: "participations.participant.nomPrenom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "titre",
          value: "",
          label: "Titre",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "",
          value: "",
          label: "Participant",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "statusActivite",
          key: "value",
          value: "",
          label: "Statut de l'activité",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "CREE", label: "Créée" },
            { value: "CLOTUREE", label: "Clôturée" },
            { value: "NON_TENUE", label: "Non tenue" },
            { value: "TENUE", label: "Tenue" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "typeActivite",
          key: "code",
          valueKey: "code",
          value: "",
          label: "Type activité",
          dropdown: true,
          url: "/typeActivites",
          visible: this.$can("filter.typeActivite", "Azoli"),
        },
      ],
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    // dateDeb(){
    //   this.queryStatistics()
    // },
    // dateFin(){
    //   this.queryStatistics()
    // }
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "titre", label: "Titre" },
        { key: "typeActivite.libelle", label: "Type" },
        { key: "datePrevue", label: "Date" },
        { key: "statusActivite", label: "Statut" },
        // { key: "creePar", label: "Traité par" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",
      transfererVersConseiller: "transfert/transfererVersConseiller",
    }),
    showDetails(activite) {
      this.activeActivite = { ...activite };
      this.$refs.detailViewer.show();
    },
    getStatusColor(activite) {
      let bg = "event-box-pending text-white";

      if (activite.statusActivite == "TENUE") {
        bg = "event-box-done text-white";
      } else if (activite.statusActivite == "NON_TENUE") {
        bg = "event-box-missed text-white";
      } else if (activite.statusActivite == "CLOTUREE") {
        bg = "event-box-reported text-white";
      }
      return bg;
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "activitePlanifie",
        search: this.search,
        fetcherMethod: "activitePlanifie/fetchActivitePlanifies",
        dataGetter: "activitePlanifie/activitePlanifies",
        paginationGetter: "activitePlanifie/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("categorieDemandeur", this.demandeurType);
          paramPlus = this.mxAddQueryParam(
            "statusActivite",
            this.statusActivite,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("sexe", this.sexeDe, paramPlus);
          paramPlus = this.mxAddQueryParam(
            "estPriseEnCharge",
            this.statutPec == "PEC",
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "estPlace",
            this.statutPlacement == "PLACE",
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "datePrevue",
            this.datePrevue != null
              ? this.$dayjs(this.datePrevue).format("YYYY-MM-DD")
              : null,
            paramPlus
          );

          let params = `createdDate=${dateDeb}&createdDate=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$router.push({ name: "espace.ce.de.edit" });
    },
  },
};
</script>

<style></style>
