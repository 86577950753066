<template>
  <div class="containe">
    <div class="row">
      <div class="col-md-12 mt-5 mb-4">
        <div class="d-flex justify-content-end mb-2">
          <div class="">
            <a
              class="btn btn-danger rounded btn-sm me-1"
              title="Générer PDF"
              @click.stop.prevent="downloadPdf(activitePlanifie)"
            >
              <i class="bi bi-printer"></i>
              Imprimer
            </a>
          </div>
        </div>
        <div
          v-if="activitePlanifie"
          class="card-booking-completed pa-5 mb-5 d-flex flex-column justify-content-center"
          :height="550"
        >
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                INFORMATION DE L'ACTIVITE
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Titre :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ activitePlanifie.titre || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Lieu :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ activitePlanifie.lieu || "-" }}
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Status :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <span
                        class="text-uppercase badge border-radius-5 py-1 px-2"
                        :class="getStatusColor(activitePlanifie)"
                        >{{ activitePlanifie.statusActivite }}</span
                      >
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Description :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ activitePlanifie.description || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Lien de la conference :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ activitePlanifie.lienConferenceEnLine || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Mode de participation :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ activitePlanifie.modeDeParticipation || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Type d'activité :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        activitePlanifie.typeActivite
                          ? activitePlanifie.typeActivite.libelle
                          : "-"
                      }}
                    </div>
                  </div>

                  <div class="col-sm-12 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Date prévue</th>
                          <th scope="col">Date début</th>
                          <th scope="col">Date fin</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{
                                $dayjs(activitePlanifie.datePrevue).format("DD/MM/YYYY")
                              }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{
                                $dayjs(activitePlanifie.dateDebut).format("DD/MM/YYYY")
                              }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{
                                $dayjs(activitePlanifie.dateDateFin).format("DD/MM/YYYY")
                              }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                LISTE DES PARTICIPANTS
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="col-sm-12 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Nom</th>
                          <th scope="col">Prénoms</th>
                          <th scope="col">Commune</th>
                          <th scope="col">Presence</th>
                          <th scope="col">Date Disponibilite</th>
                          <th scope="col">Observation</th>
                          <th scope="col">Motif d'absence</th>
                          <th scope="col">Rapport</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(participant, index) in activitePlanifie.participations"
                        :key="index"
                      >
                        <tr>
                          <td>
                            <span>
                              {{ participant.participant ? participant : "-" }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                participant.participant
                                  ? participant.participant.prenom
                                  : "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                participant.participant
                                  ? participant.participant.commune.nom
                                  : "-"
                              }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{ participant.presence || "-" }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{
                                $dayjs(participant.dateDisponibilite).format(
                                  "DD/MM/YYYY"
                                )
                              }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{ participant.observation || "-" }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{ participant.motifAbsence || "-" }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{ participant.rapport || "-" }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["activiteId", "item"],
  components: {},
  data() {
    return {};
  },
  created() {
    if (this.activiteId) {
      this.fetchActivitePlanifie({
        activiteId: this.activiteId,
      });
      //   this.fetchParticipations({
      //     extraQueryArg: `activiteId=${this.activiteId}`,
      //   }).then;
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      // activitePlanifie: "activitePlanifie/activitePlanifie",
    }),
    activitePlanifie() {
      return this.item;
    },
  },
  methods: {
    ...mapActions({
      fetchActivitePlanifie: "activitePlanifie/fetchActivitePlanifie",
      //   fetchParticipations: "participation/fetchParticipations",
    }),
    downloadPdf(activite) {
      this.mxExportToPDF({
        meta: {
          title: "Liste des présences au poste",
          subtitle: "Liste des présences au poste",
        },
        pathParams: {
          name: "common.export",
          query: {
            apiPath: "participantActivies/exportToPdf",
            queryParams: this.mxGetQueryBuilder(),
            fileName: "Liste des présences au poste.pdf",
          },
        },
      });
    },
    getStatusColor(activite) {
      let bg = "event-box-pending text-white";

      if (activite.statusActivite == "TENUE") {
        bg = "event-box-done text-white";
      } else if (activite.statusActivite == "NON_TENUE") {
        bg = "event-box-missed text-white";
      } else if (activite.statusActivite == "CLOTUREE") {
        bg = "event-box-reported text-white";
      }
      return bg;
    },
  },
};
</script>

<style>
.card-title {
  color: #1a32c8 !important;
  /* color: #1A32C8 !important; */
  text-transform: uppercase;
}
hr {
  color: #bfa9a9;
  margin: 10px 5px;
}
</style>
