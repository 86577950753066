<template>
  <div>
    <slot name="action" :show="show">
      <a href="#" class="style_btn btn btn-warning" v-b-modal.modal-footer-sm>Ajouter</a>
      <!-- <b-button variant="style_btn btn btn-warning" v-b-modal.modal-footer-sm>Créer</b-button> -->
    </slot>
    <b-modal id="modal-footer-sm" :size="size" ref="modal" hide-footer :title="title" modal-ok="Valider" modal-cancel="Annuler">
      <template #modal-header>
        <h5 class="modal-title" id="staticBackdropLabel">Sélection de conseiller/ulpe</h5>
        <button type="button" class="style_btn btn-close" data-bs-dismiss="modal" @click.prevent="cancel" aria-label="Close"></button>
      </template>
      <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()" >
          <div class="row">
            <div class="col-sm-12">
                  <!-- <div class="form-floating mb-3">
                    <input v-model="mxSearch" type="text" class="form-control" id="search" name="search" placeholder="Rechercher">
                    <label for="name">Rechercher un conseiller ou un ulp</label>
                    <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                  </div> -->
                  <div class="input-group me-4">
                    <input v-model="mxSearch" type="text" placeholder="Rechercher un conseiller ou un ULP par nom ou par matricule" aria-describedby="button-query" class="form-control"/>
                    <button type="submit" id="button-query" class="style_btn btn btn-primary"><i class="bi bi-search"></i></button>
                  </div>
            </div>
          </div>
          <div class="col-sm-12 my-3 text-muted">Cliquez sur le profil du conseiller qui doit suivre le dossier du(des) agent(s) sélectionné(s).</div>
          <span v-if="error"  class="text-danger">Vous devez sélectionner au moins un profil</span>
          <div class="row mb-4" v-if="viewMode=='card'">
            <div class="col-sm-6 col-lg-4 mb-3" v-for="de in mxFilteredItems" :key="de.id">
                <div :class="{ 'border card-primary border-primary': selectedItem.id == de.id }" class="style_profil_card p-3" @click="selectProfile(de)">
                    <div class="mx-auto">
                        <a href="#">
                            <div class="rounded-circle style_bg_img border" style="background-image: url('/assets/img/profilMan.jpg'); height: 100px; width: 100px;">
                            </div>
                        </a>
                    </div>
                    <div class="py-3 text-center border-bottom">
                        <div class="d-flex no-wrap justify-content-center align-items-center text-muted">
                            <small class="mt-1">4,5 | 7 avis</small>
                        </div>
                        <h6 class="my-1"><a href="#" class="text-dark">{{de.nom}} {{de.prenom}} {{de.email}}</a></h6>
                        <small class="text-muted" v-if="de.creePar">Réalisé par: {{ de.creePar.nom }} {{ de.creePar.nom }}, le {{ de.creePar.nom }}</small>
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                        <a class="btn btn-success rounded-0 btn-sm me-1"><i class="bi bi-pencil-square"></i></a>
                        <a class="btn btn-info rounded-0 btn-sm me-1"><i class="bi bi-info-square"></i></a>
                        <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a>
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-12">
              <div class="d-flex flex-wrap justify-content-center align-items-center">
                <b-pagination
                  v-model="mxPagination.page"
                  :total-rows="mxPageCount"
                  :per-page="mxPagination.rowsPerPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div> -->
        </div>
          <div v-else class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table selectable show-empty empty-text="Aucune donnée à afficher" :fields="tableFields" class="align-middle" striped :items="tableData">
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" :id="`${data.index}`" name="disponible" :value="data.rowSelected">
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input @change="()=> data.rowSelected=true" class="form-check-input" type="checkbox" :id="`${data.index}`" name="disponible" :value="data.index">
                  </div>
                </template>

                <template #cell(lastLogin)="data">
                    <span>{{data.item.lastLogin | dayjs('DD-MM-YYYY') }}</span>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>Affichage de l’élément {{ mxPagination.page *mxPagination.rowsPerPage }} à {{ (mxPagination.page + 1)*mxPagination.rowsPerPage }} de {{ mxTotalCount }} éléments</p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxPageCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
          <div class="d-grid gap-2 mt-3">
            <button @click.prevent="save" type="submit" class="style_btn btn btn-success btn-lg">Enrégistrer</button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { paginatorMixin } from '@/mixins/paginator-mixin'

  export default {
    props: {
      title: {
        type:  String,
        required: true
      },
      size: {
        type:  String,
        default: 'md'
      },
      item: {
        type:  Object,
        default: () => ({ })
      }
    },
    mixins: [paginatorMixin],
    data () {
      return {
        editableItem: {},
        search: '',
        viewMode: 'card',//card, table
        filterOptions: [],
        selectedItem: {},
        error: false
      }
    },
    watch: {
      item () {
        this.editableItem = { ...this.item }
      }
    },
    computed: {
      options () {
        return this.structures.map(item => ({ value: item, text: item.libelle }) )
      },
      tableFields () {
        return [
          '#',
          'index',
          { key: 'nom', label: 'Nom' },
          { key: 'prenom', label: 'Prénom' },
          { key: 'sexe', label: 'Sexe' },
          { key: 'phone', label: 'Téléphone' },
          { key: 'commune.nom', label: 'Commune' },
        ]
      },
      tableData () {
        return this.mxFilteredItems.map(item => {
          return { ...item }
        })
      },
      sexeOptions () {
        return ['M', 'F'].map(item => ({ value: item, text: item }) )
      },
    },
    methods: {
      resetContent(){
        this.mxInitializePaginator({
          queryModel: 'agent',
          search: this.search,
          fetcherMethod: 'agent/fetchAntenneAgentConseillers',
          dataGetter: 'agent/agents',
          paginationGetter: 'agent/pagination',
          pagination: {
            sortBy: 'desc',


            descending: false,
            page: 1,
            rowsPerPage: 5
          },
          filterOptions: this.filterOptions,
          searchFields: [ 'search', 'nom', 'email' ]
        })
      },
      selectProfile(de){
        this.error = false
        this.selectedItem = de
      },
      show () {
        this.resetContent()
        this.$refs['modal'].show()
      },
      hide () {
        this.$refs['modal'].hide()
      },
      ok () {
        this.$emit('saved', this.selectedItem)
        this.editableItem = {}
      },
      cancel () {
        this.hide()
        this.$emit('canceled')
      },
      handleInput(e){
        this.editableItem[e.target.name] = e.target.value
        this.$emit('input', this.editableItem)
      },
      async isCompleted () {
        const valid = await this.$refs.observer.validate()
        return valid
      },
      async save(){
        if(!await this.isCompleted()) return;
        
        if(this.selectedItem.id == null){
          this.error = true
          return
        }
        
        this.$emit('saved', this.selectedItem)
        this.hide()
      },
    }
    
  }
</script>